<template>
    <div>
        <router-view></router-view>
    </div>
</template>
<script>
export default {
    name: 'themeEditorLayout',
    components: {
    },
    beforeMount(){

        var element = document.getElementsByTagName("body")[0];

        element.classList.remove("activeIpad");
        element.classList.add("hideSideBar");

    },
}
</script>