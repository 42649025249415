<template>
    <div class="sideBarMenu">
        <div class="sideMenuLogo">
            <router-link to='/'>
                <img src="../assets/images/yeet/yeet_logo_white.png" alt="">
            </router-link>
            <button class="hideSideMenuBtn" @click="showHide">
                <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                    <title/>
                    <g data-name="1" id="_1">
                        <path d="M441.13,166.52h-372a15,15,0,1,1,0-30h372a15,15,0,0,1,0,30Z"/>
                        <path d="M441.13,279.72h-372a15,15,0,1,1,0-30h372a15,15,0,0,1,0,30Z"/>
                        <path d="M441.13,392.92h-372a15,15,0,1,1,0-30h372a15,15,0,0,1,0,30Z"/>
                    </g>
                </svg>
            </button>
        </div>
        <div class="menuItems">
            <ul>
                <li>
                    <router-link to="/">
                        <span>
                            <img src="../assets/images/dashboardIcon/Dashboard.svg" alt="">
                        </span>
                        Dashboard
                    </router-link>
                </li>
                <li 
                    class="dropDownZone submenu_3" 
                    data-toggle="collapse" 
                    data-target="#submenu_active_8" 
                    aria-expanded="false" 
                    aria-controls="submenu_active_8" 
                    :class="findIsActive('navigation') ? 'parentActive' : '' "
                >
                    <a href="javascript:;">
                        <span>
                            <img src="../assets/images/dashboardIcon/Navigation.svg" alt="">
                        </span>
                        Navigation
                    </a>
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-down" class="svg-inline--fa fa-angle-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                        <path d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z"></path>
                    </svg>
                </li>
                <ul ref="sub_menu" id="submenu_active_8" class="subSideMenu submenu_active_8 collapse">
                    <li>
                        <router-link to="/navigation/header-navigation">
                            Header Navigation
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/navigation/footer-navigation">
                            Footer Navigation
                        </router-link>
                    </li>
                </ul>
                <li 
                    data-toggle="collapse" 
                    data-target="#submenu_active_1" 
                    aria-expanded="true" 
                    aria-controls="submenu_active_1"
                    class="dropDownZone submenu_1" 
                    :class="findIsActive('ecommerce') ? 'parentActive' : '' "
                >
                    <a href="javascript:;">
                        <span>
                            <img src="../assets/images/dashboardIcon/E-Commerce.svg" alt="">
                        </span>
                        E-Commerce
                    </a>
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-down" class="svg-inline--fa fa-angle-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                        <path d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z"></path>
                    </svg>
                </li>
                <ul ref="sub_menu" id="submenu_active_1" class="subSideMenu submenu_active_1 collapse show">
                    <li :class="roleCheck('manage orders') ? 'noAccess': '' ">
                        <router-link 
                            to="/e-commerce/order" 
                            :data-toggle="roleCheck('manage orders') ? 'tooltip' : '' " 
                            :data-placement="roleCheck('manage orders') ? 'top' : '' " 
                            :title="roleCheck('manage orders') ? 'you dont have permission to manage orders' : '' "
                        >
                            Orders
                        </router-link>
                    </li>
                    <li :class="roleCheck('manage products') ? 'noAccess': '' ">
                        <router-link 
                            to="/e-commerce/product" 
                            :data-toggle="roleCheck('manage products') ? 'tooltip' : '' " 
                            :data-placement="roleCheck('manage products') ? 'top' : '' " 
                            :title="roleCheck('manage products') ? 'you dont have permission to manage products' : '' "
                        >
                            Products
                        </router-link>
                    </li>
                    <li :class="roleCheck('manage products') ? 'noAccess': '' " v-if="isDev()">
                        <router-link 
                            to="/e-commerce/product-bundle" 
                            :data-toggle="roleCheck('manage products') ? 'tooltip' : '' " 
                            :data-placement="roleCheck('manage products') ? 'top' : '' " 
                            :title="roleCheck('manage products') ? 'you dont have permission to manage products' : '' "
                        >
                            Product Bundles
                        </router-link>
                    </li>
                    <li :class="roleCheck('manage products') ? 'noAccess': '' ">
                        <router-link 
                            to="/e-commerce/category" 
                            :data-toggle="roleCheck('manage products') ? 'tooltip' : '' " 
                            :data-placement="roleCheck('manage products') ? 'top' : '' " 
                            :title="roleCheck('manage products') ? 'you dont have permission to manage products' : '' "
                        >
                            Categories
                        </router-link>
                    </li>
                    <li :class="roleCheck('manage products') ? 'noAccess': '' ">
                        <router-link 
                            to="/e-commerce/brand" :data-toggle="roleCheck('manage products') ? 'tooltip' : '' " 
                            :data-placement="roleCheck('manage products') ? 'top' : '' " 
                            :title="roleCheck('manage products') ? 'you dont have permission to manage products' : '' "
                        >
                            Brands
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/e-commerce/customer">
                            Customers
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/e-commerce/payment-method">
                            Payment Methods
                        </router-link>
                    </li>
                    <li :class="findSubActive('ecommerce') ? 'parentActive' : '' ">
                        <router-link to="/e-commerce/shipping-region">
                            Shipping Regions
                        </router-link>
                    </li>
                </ul>
                <li 
                    data-toggle="collapse" 
                    data-target="#submenu_active_2" 
                    aria-expanded="false" 
                    aria-controls="submenu_active_2"
                    class="dropDownZone submenu_2" 
                    :class="findIsActive('marketing') ? 'parentActive' : '' "
                >
                    <a href="javascript:;">
                        <span>
                            <img src="../assets/images/dashboardIcon/promo_Marketing.svg" alt="">
                        </span>
                        Promo & Marketing
                    </a>
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-down" class="svg-inline--fa fa-angle-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                        <path d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z"></path>
                    </svg>
                </li>
                <ul ref="sub_menu" id="submenu_active_2" class="subSideMenu submenu_active_2 collapse">
                    <li>
                        <router-link to="/marketing/coupon">
                            Coupon & Discounts
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/marketing/pixel-management">
                            Pixel Management
                        </router-link>
                    </li>
                    <li v-if="isDev()">
                        <router-link to="/marketing/facebook-shop">
                            Facebook Shop
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/marketing/google-shop">
                            Google Shop
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/marketing/social-media">
                            Social Media
                        </router-link>
                    </li>
                    <li v-if="isDev()">
                        <router-link to="/marketing/marketing-tools">
                            Marketing Tools
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/marketing/dropshipping">
                            Dropshipping
                        </router-link>
                    </li>
                </ul>
                <li 
                    data-toggle="collapse" 
                    data-target="#submenu_active_3" 
                    aria-expanded="false" 
                    aria-controls="submenu_active_3"
                    class="dropDownZone submenu_2" 
                    :class="findIsActive('seo') ? 'parentActive' : '' "
                >
                    <a href="javascript:;">
                        <span>
                            <img src="../assets/images/dashboardIcon/SEO.svg" alt="">
                        </span>
                        SEO
                    </a>
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-down" class="svg-inline--fa fa-angle-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                        <path d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z"></path>
                    </svg>
                </li>
                <ul ref="sub_menu" id="submenu_active_3" class="subSideMenu submenu_active_3 collapse">
                    <li>
                        <router-link to="/seo/general">
                            General
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/seo/redirect">
                            Redirects
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/seo/robot">
                            Robots
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/seo/page-detail">
                            Page Details
                        </router-link>
                    </li>
                    <!-- <li>
                        <router-link to="/seo/no-index-url">
                            No Index Urls
                        </router-link>
                    </li> -->
                    <li>
                        <router-link to="/seo/search-appearance">
                            Search Appearance
                        </router-link>
                    </li>
                </ul>
                <li 
                    class="dropDownZone submenu_3" 
                    data-toggle="collapse" 
                    data-target="#submenu_active_4" 
                    aria-expanded="false" 
                    aria-controls="submenu_active_4"
                    :class="findIsActive('content-management') ? 'parentActive' : roleCheck('manage content') ? 'noAccess': '' "
                >
                    <a href="javascript:;">
                        <span>
                            <img src="../assets/images/dashboardIcon/content_Management.svg" alt="">
                        </span>Content Management
                    </a>
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-down" class="svg-inline--fa fa-angle-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                        <path d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z"></path>
                    </svg>
                </li >
                <ul ref="sub_menu" id="submenu_active_4" class="subSideMenu submenu_active_4 collapse">
                    <li :class="roleCheck('manage content') ? 'noAccess':''" >
                        <router-link to="/content-management/blog-category" :data-toggle="roleCheck('manage content') ? 'tooltip' : '' " :data-placement="roleCheck('manage content') ? 'top' : '' " :title="roleCheck('manage content') ? 'you dont have permission to manage content' : '' ">
                            Blog Categories
                        </router-link>
                    </li>
                    <li  :class="roleCheck('manage content') ? 'noAccess': '' " >
                        <router-link to="/content-management/blog" :data-toggle="roleCheck('manage content') ? 'tooltip' : '' " :data-placement="roleCheck('manage content') ? 'top' : '' " :title="roleCheck('manage content') ? 'you dont have permission to manage content' : '' ">
                            Blog Posts
                        </router-link>
                    </li>
                    <li :class="roleCheck('manage content') ? 'noAccess': ''">
                        <router-link to="/content-management/gallery" :data-toggle="roleCheck('manage content') ? 'tooltip' : '' " :data-placement="roleCheck('manage content') ? 'top' : '' " :title="roleCheck('manage content') ? 'you dont have permission to manage content' : '' ">
                            Gallery
                        </router-link>
                    </li>
                    <li :class="roleCheck('manage content') ? 'noAccess': ''">
                        <router-link to="/content-management/faq" :data-toggle="roleCheck('manage content') ? 'tooltip' : '' " :data-placement="roleCheck('manage content') ? 'top' : '' " :title="roleCheck('manage content') ? 'you dont have permission to manage content' : '' ">
                            FAQs
                        </router-link>
                    </li>
                </ul>
                <li 
                    class="dropDownZone submenu_3" 
                    data-toggle="collapse" 
                    data-target="#submenu_active_5" 
                    aria-expanded="false" 
                    aria-controls="submenu_active_5" 
                    :class="findIsActive('settings') ? 'parentActive' : roleCheck('manage settings') ? 'noAccess' : ''"
                >
                    <a href="javascript:;">
                        <span>
                            <img src="../assets/images/dashboardIcon/Settings.svg" alt="">
                        </span>
                        Settings
                    </a>
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-down" class="svg-inline--fa fa-angle-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                        <path d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z"></path>
                    </svg>
                </li>
                <ul ref="sub_menu" id="submenu_active_5" class="subSideMenu submenu_active_5 collapse">
                    <li :class="roleCheck('manage settings') ? 'noAccess': ''">
                        <router-link 
                            to="/settings/store-settings" 
                            :data-toggle="roleCheck('manage products') ? 'tooltip' : '' " 
                            :data-placement="roleCheck('manage settings') ? 'top' : '' " 
                            :title="roleCheck('manage settings') ? 'you dont have permission to manage settings' : '' "
                        >
                            Store Settings
                        </router-link>
                    </li>
                    <li :class="roleCheck('manage settings') ? 'noAccess': '' ">
                        <router-link to="/settings/domain-setup">
                            Domain Setup
                        </router-link>
                    </li>
                    <li :class="roleCheck('manage settings') ? 'noAccess': '' ">
                        <router-link to="/subscription">
                            Subscription
                        </router-link>
                    </li>
                    <li :class="roleCheck('manage settings') ? 'noAccess': '' ">
                        <router-link to="/settings/privacy-policy">
                            Privacy Policy
                        </router-link>
                    </li>
                    <li :class="roleCheck('manage settings') ? 'noAccess': '' ">
                        <router-link to="/settings/terms-and-conditions">
                            Terms & Conditions
                        </router-link>
                    </li>
                </ul>
                <li 
                    data-toggle="collapse" 
                    data-target="#submenu_active_6" 
                    aria-expanded="false" 
                    aria-controls="submenu_active_6"  
                    class="dropDownZone submenu_2" 
                    :class="findIsActive('theme-management') ? 'parentActive' : (roleCheck('manage content') && roleCheck('manage settings')) ? 'noAccess' : ''"
                >
                    <a href="javascript:;">
                        <span>
                            <img src="../assets/images/dashboardIcon/theme_Management.svg" alt="">
                        </span>
                        Theme Management
                    </a>
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-down" class="svg-inline--fa fa-angle-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                        <path d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z"></path>
                    </svg>
                </li>
                <ul ref="sub_menu" id="submenu_active_6" class="subSideMenu submenu_active_6 collapse">
                    <li :class="findSubActive('theme-management/theme') ? 'parentActive' : roleCheck('manage settings') ? 'noAccess': ''">
                        <router-link to="/theme-management/theme">
                            Themes
                        </router-link>
                    </li>
                    <li :class="findSubActive('theme-management') ? 'parentActive' : roleCheck('manage content') ? 'noAccess': ''">
                        <router-link 
                            to="/theme-management/theme-customization" 
                            :data-toggle="roleCheck('manage content') ? 'tooltip' : ''" 
                            :data-placement="roleCheck('manage content') ? 'top' : ''" 
                            :title="roleCheck('manage content') ? 'you dont have permission to manage content' : '' "
                        >
                            Theme Customization
                        </router-link>
                    </li>
                </ul>
                <li 
                    class="dropDownZone submenu_3" 
                    data-toggle="collapse" 
                    data-target="#submenu_active_7" 
                    aria-expanded="false" 
                    aria-controls="submenu_active_7"
                    :class="findIsActive('expenses-purchases') ? 'parentActive' : roleCheck('manage expense') ? 'noAccess': ''"
                >
                    <a href="javascript:;">
                        <span>
                            <img src="../assets/images/dashboardIcon/expense-purchase.svg" alt="">
                        </span>
                        Expenses/Purchases
                    </a>
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-down" class="svg-inline--fa fa-angle-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                        <path d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z"></path>
                    </svg>
                </li>
                <ul ref="sub_menu" id="submenu_active_7" class="subSideMenu submenu_active_7 collapse">
                    <li :class="roleCheck('manage expense') ? 'noAccess': ''">
                        <router-link 
                            to="/expenses-purchases/account" 
                            :data-placement="roleCheck('manage expense') ? 'top' : '' " 
                            :title="roleCheck('manage expense') ? 'you dont have permission to manage expense' : '' "
                        >
                            Banking/Accounts
                        </router-link>
                    </li>
                    <li :class="roleCheck('manage expense') ? 'noAccess': ''">
                        <router-link 
                            to="/expenses-purchases/expense" 
                            :data-placement="roleCheck('manage expense') ? 'top' : ''" 
                            :title="roleCheck('manage expense') ? 'you dont have permission to manage expense' : '' "
                        >
                            Expenses
                        </router-link>
                    </li>
                    <li :class="roleCheck('manage expense') ? 'noAccess': ''">
                        <router-link 
                            to="/expenses-purchases/vendor" 
                            :data-placement="roleCheck('manage expense') ? 'top' : ''" 
                            :title="roleCheck('manage expense') ? 'you dont have permission to manage expense' : '' "
                        >
                            Vendors
                        </router-link>
                    </li>
                    <li :class="findSubActive('expenses-purchases') ? 'parentActive' :roleCheck('manage expense') ? 'noAccess': ''">
                        <router-link 
                            to="/expenses-purchases/bill" 
                            :data-placement="roleCheck('manage expense') ? 'top' : ''" 
                            :title="roleCheck('manage expense') ? 'you dont have permission to manage expense' : '' "
                        >
                            Bills
                        </router-link>
                    </li>
                </ul>
                <li :class="findSubActive('notifications') ? 'parentActive' :''">
                    <router-link to="/notification">
                        <span>
                            <img src="../assets/images/dashboardIcon/Help.svg" alt="">
                        </span>
                        Notifications
                    </router-link>
                </li>
                <li>
                    <router-link to="/support-member">
                        <span>
                            <img src="../assets/images/dashboardIcon/customer_communication.svg" alt="">
                        </span>
                        Support Members
                    </router-link>
                </li>
                <li :class="roleCheck('manage admins') ? 'noAccess': ''">
                    <router-link to="/user-management">
                        <span>
                            <img src="../assets/images/dashboardIcon/user_management.svg" alt="">
                        </span>
                        User Management
                    </router-link>
                </li>
                <li 
                    class="dropDownZone submenu_3" 
                    data-toggle="collapse" 
                    data-target="#submenu_active_8" 
                    aria-expanded="false" 
                    aria-controls="submenu_active_8"
                    :class="findIsActive('crm') ? 'parentActive' : roleCheck('manage crm') ? 'noAccess': ''"
                >
                    <a href="javascript:;">
                        <span>
                            <img src="../assets/images/dashboardIcon/user_management.svg" alt="">
                        </span>
                        Customer Relationship Management
                    </a>
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-down" class="svg-inline--fa fa-angle-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                        <path d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z"></path>
                    </svg>
                </li>
                <ul ref="sub_menu" id="submenu_active_8" class="subSideMenu submenu_active_8 collapse">
                    <li :class="roleCheck('manage crm') ? 'noAccess': ''">
                        <router-link 
                            to="/crm/contact" 
                            :data-placement="roleCheck('manage crm') ? 'top' : ''" 
                            :title="roleCheck('manage crm') ? 'you dont have permission to manage crm' : '' "
                        >
                            Contacts
                        </router-link>
                    </li>
                    <li :class="roleCheck('manage crm') ? 'noAccess': ''">
                        <router-link 
                            to="/crm/group" 
                            :data-placement="roleCheck('manage crm') ? 'top' : ''" 
                            :title="roleCheck('manage crm') ? 'you dont have permission to manage crm' : '' "
                        >
                            Groups
                        </router-link>
                    </li>
                    <li :class="roleCheck('manage crm') ? 'noAccess': ''">
                        <router-link 
                            to="/crm/email" 
                            :data-placement="roleCheck('manage crm') ? 'top' : ''" 
                            :title="roleCheck('manage crm') ? 'you dont have permission to manage crm' : '' "
                        >
                            Emails
                        </router-link>
                    </li>
                    <li :class="roleCheck('manage crm') ? 'noAccess': ''">
                        <router-link 
                            to="/crm/smtp" 
                            :data-placement="roleCheck('manage crm') ? 'top' : ''" 
                            :title="roleCheck('manage crm') ? 'you dont have permission to manage crm' : '' "
                        >
                            SMTPs
                        </router-link>
                    </li>
                </ul>
            </ul>
        </div>
        <div class="packageLine" v-if="settingsLoader">
            <div class="packageLineBlock">
                <vue-skeleton-loader
                  type="rect"
                  :width="248"
                  :height="24"
                  animation="fade"
                />
                <div class="packageBtnrow">
                    <div class="packageTitleCol" >
                        <vue-skeleton-loader
                            type="rect"
                            :width="248"
                            :height="24"
                            animation="fade"
                        />
                    </div>
                </div>
                <vue-skeleton-loader
                    type="rect"
                    :width="248"
                    :height="24"
                    animation="fade"
                />
                <div class="progressBarRow">
                    <vue-skeleton-loader
                        type="rect"
                        :width="248"
                        :height="65"
                        animation="fade"
                    />
                </div>
            </div>
            <div class="userDetailInfo">
                <vue-skeleton-loader
                    type="rect"
                    :width="247"
                    :height="50"
                    animation="fade"
                />
            </div>
        </div>
        <div class="packageLine" v-else>
            <div class="packageLineBlock">
                <div class="packageBlock">
                    <p>Package</p>
                    <div>
                        <button data-toggle="tooltip" data-placement="top" title="Youtube Tutorials" @click="jumpToTutorialsHandle">
                            <svg id="Layer_1" style="enable-background:new 0 0 512 512;" version="1.1" viewBox="0 0 512 512"  xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                <g>
                                    <g>
                                        <path d="M265,96c65.3,0,118.7,1.1,168.1,3.3l0.7,0h0.7c23.1,0,42,22,42,49.1v1.1l0.1,1.1c2.3,34,3.4,69.3,3.4,104.9v0v0    c0.1,35.6-1.1,70.9-3.4,104.9l-0.1,1.1v1.1c0,13.8-4.7,26.6-13.4,36.1c-7.8,8.6-18,13.4-28.6,13.4h-0.8l-0.8,0    c-52.9,2.5-108.8,3.8-166.4,3.8c-3.5,0-7.1,0-10.6,0H256h-0.1c-3.6,0-7.2,0-10.8,0c-57.8,0-113.7-1.3-166.2-3.7l-0.8,0h-0.8    c-10.6,0-20.7-4.8-28.5-13.4c-8.6-9.5-13.4-22.3-13.4-36.1v-1.1l-0.1-1.1c-2.4-34.1-3.5-69.4-3.3-104.7v-0.1v-0.1    c-0.1-35.3,1-70.5,3.3-104.6l0.1-1.1v-1.1c0-27.2,18.8-49.3,41.9-49.3H78l0.7,0c49.5-2.3,102.9-3.3,168.2-3.3h9H265 M265,64    c-3,0-6,0-9,0s-6,0-9,0c-57.6,0-114.2,0.8-169.6,3.3c-40.8,0-73.9,36.3-73.9,81.3C1,184.4-0.1,220,0,255.7    c-0.1,35.7,0.9,71.3,3.4,107c0,45,33.1,81.6,73.9,81.6c54.8,2.6,110.7,3.8,167.8,3.8c3.6,0,7.3,0,10.9,0c3.6,0,7.2,0,10.7,0    c57.1,0,113-1.2,167.9-3.8c40.9,0,74-36.6,74-81.6c2.4-35.7,3.5-71.4,3.4-107.1c0.1-35.7-1-71.3-3.4-107.1c0-45-33.1-81.1-74-81.1    C379.2,64.8,322.7,64,265,64L265,64z"/>
                                    </g>
                                    <g>
                                        <path d="M207,353.8V157.4l145,98.2L207,353.8z"/>
                                    </g>
                                </g>
                            </svg>
                        </button>
                    </div>
                </div>
                <div class="packageBtnrow">
                    <div class="packageTitleCol" >
                        <h5>{{ settings.subscription ? settings.subscription.package.name : ''}}</h5>
                    </div>
                    <div class="packageButtonCol" v-if="usagePercentage <= 20">
                        <button @click.prevent="$router.push({path:'/subscription'})">Upgrade</button>
                    </div>
                </div>
                <p>Disk Usage</p>
                <div class="progressBarRow">
                    {{ usagePercentage }}
                    <div class="progressBarTrack">
                        <div class="progressBar" :style="{'width':(100 - usagePercentage) + '%'}"></div>
                    </div>
                    <div class="progressBarCtmRow">
                        <div class="progressBarCtmColumn">
                            <p>{{remainingSpace}} GB (Remaining)</p>
                        </div>
                        <div class="progressBarCtmColumn">
                            <p>{{totalSpace}} GB</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="userDetailInfo">
                <div class="userDetailInfoImg">
                    <img 
                        :src="settings.logo ? base_image_url+settings.store_id+ '/storeConfiguration/' +settings.logo : '/images/WebLogo@2x.jpg'" 
                        alt=""
                        @error="handleImageError"
                    >
                </div>
                <div class="userContact">
                    <h4>{{settings.name}}</h4>
                    <a :href="`http://`+settings.secondary_domain" target="_blank" v-if="settings.secondary_domain">VISIT STORE</a>
                    <a :href="`http://`+settings.domain" target="_blank" v-else>VISIT STORE</a>
                </div>
                <div class="dropdown pl-0">
                    <button id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <svg enable-background="new 0 0 32 32" id="Glyph" version="1.1" viewBox="0 0 32 32" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <path d="M16,13c-1.654,0-3,1.346-3,3s1.346,3,3,3s3-1.346,3-3S17.654,13,16,13z" id="XMLID_287_"/>
                            <path d="M6,13c-1.654,0-3,1.346-3,3s1.346,3,3,3s3-1.346,3-3S7.654,13,6,13z" id="XMLID_289_"/>
                            <path d="M26,13c-1.654,0-3,1.346-3,3s1.346,3,3,3s3-1.346,3-3S27.654,13,26,13z" id="XMLID_291_"/>
                        </svg>
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <button class="dropdown-item" @click.prevent="$route.fullPath != '/settings/profile' ? $router.push({path:'/settings/profile'}) : ''">
                            Profile
                        </button>
                        <button class="dropdown-item" @click.prevent="$route.fullPath != '/settings/store-settings' ? $router.push({path:'/settings/store-settings'}) : ''">
                            Settings
                        </button>
                        <button class="dropdown-item" @click.prevent="$store.dispatch('logout');">
                            Logout
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {mapGetters} from 'vuex';
export default {
    name: 'SideBar',
    data:()=>({
        base_image_url: process.env.VUE_APP_STORE_IMAGE_BASE_URL,
        usagePercentage: 0,
        totalSpace: 0,
        remainingSpace: 0,
    }),
    methods: {
        showHide(){

            var element = document.getElementsByTagName("body")[0];

            element.classList.add("hideSideBar");

            element.classList.remove("activeIpad");

        },
        findIsActive(parent){
            
            if(parent == "ecommerce"){

                if(this.$route.name == "Orders" ||
                this.$route.name == "Products" ||
                this.$route.name == "Product Bundles" ||
                this.$route.name == "Categories" ||
                this.$route.name == "Brands" ||
                this.$route.name == "Customers" ||
                this.$route.name == "Payment Methods" ||
                this.$route.name == "Shipping Regions" ||
                this.$route.name == "Add shipping Region" ||
                this.$route.name == "Edit Shipping Region"){

                    return true;

                }else{

                    return false;

                }

            }else if(parent == "navigation"){

                if(this.$route.name == "Header Navigation" ||
                this.$route.name == "Footer Navigation"){

                    return true;

                }else{

                    return false;

                }

            }else if(parent == "marketing"){

                if(this.$route.name == "Coupons" ||
                this.$route.name == "Pixel Management" || 
                this.$route.name == "Google Shop" || 
                this.$route.name == "Facebook Shop" ||
                this.$route.name == "Social Media" ||
                this.$route.name == "Marketing Tools" ||
                this.$route.name == "Social Media" ||
                this.$route.name == "Google Shop" || 
                this.$route.name == 'Dropshipping'){

                    return true;

                }else{

                    return false;

                }


            }else if(parent == "seo"){

                if(this.$route.name == "GENERAL" ||
                this.$route.name == "Redirects" ||
                this.$route.name == "Robots" ||
                this.$route.name == "Search Appearance" ||
                this.$route.name == "Page Details" ||
                this.$route.name == "No Index Urls"){

                    return true;

                }else{

                    return false;

                }

            }else if(parent == "content-management"){

                if(this.$route.name == "Blogs" ||
                this.$route.name == "Blog Category" ||
                this.$route.name == "FAQs" ||
                this.$route.name == "Gallery"){

                    return true;

                }else{

                    return false;

                }

            }else if(parent == "settings"){

                if(this.$route.name == "Store Settings" ||
                this.$route.name == "Domains" ||
                this.$route.name == "Subscription" ||
                this.$route.name == "Privacy Policy" ||
                this.$route.name == "Terms and Conditions"){

                    return true;

                }else{

                    return false;

                }

            }else if(parent == 'theme-management'){

                if(this.$route.name == "Themes" ||
                this.$route.name == "Theme Customization" ||
                this.$route.name == "Home Banner"){

                    return true;

                }else{

                    return false;

                }

            }else if(parent == "expenses-purchases"){

                if(this.$route.name == "Bills" ||
                this.$route.name == "Add Bill" ||
                this.$route.name == "Edit Bill" ||
                this.$route.name == "Expenses" ||
                this.$route.name == "Vendors" ||
                this.$route.name == "Accounts"){

                    return true;

                }else{

                    return false;

                }

            }else if(parent == "crm"){

                if(this.$route.name == "Contacts" ||
                this.$route.name == "Groups" ||
                this.$route.name == "Emails" ||
                this.$route.name == "SMTPs"){

                    return true;

                }else{

                    return false;

                }

            }

        },
        findSubActive(parent){

            if(parent == "ecommerce"){

                if(this.$route.name == "Add shipping Region" ||
                this.$route.name == "Edit Shipping Region"){

                    return true;

                }else{

                    return false;

                }

            }else if(parent == "content-management"){

                if(this.$route.name == "Blogs" ||
                this.$route.name == "Blog Category"){

                    return true;

                }else{

                    return false;

                }

            }else if(parent == "expenses-purchases"){

                if(this.$route.name == "Bills" ||
                this.$route.name == "Add Bill" ||
                this.$route.name == "Edit Bill"){

                    return true;

                }else{

                    return false;

                }

            }else if(parent == "theme-management"){

                if(this.$route.name == 'Home Banner'){

                    return true;

                }else{

                    return false;
                    
                }

            }else if(parent == "notifications"){

                if(this.$route.name == "History"){

                    return true;

                }else{

                    return false;

                }

            }else if(parent == "crm"){

                if(this.$route.name == 'Contacts' ||
                this.$route.name == 'Groups' ||
                this.$route.name == 'Emails' ||
                this.$route.name == 'SMTPs'){

                    return true;

                }else{

                    return false;

                }

            }
            
        },
        roleCheck(role){

            let isNotFound = true;

            this.$store.state.roles.forEach(userRole =>{

                if(userRole.name == role){

                    isNotFound = false;

                }

            });

            return isNotFound;

        },
        jumpToTutorialsHandle(){
            
            const url = 'https://www.youtube.com/watch?v=5r1lqqr5zkU';

            window.open(url, '_blank');
            
        },

        //dev env check
        isDev(){

            if(process.env.VUE_APP_ENV == 'dev'){

                return true;

            }else{

                return false;

            }

        },

        //image loading error handling
        handleImageError(e){

            e.target.src = '/images/image_not_found.png'

        },

    },
    computed:{
        ...mapGetters({
            settings:'settings_module/settings',
            settingsLoader: "settings_module/isPending",
            logoutIsPending:'logoutIsPending'
        }),
    },
    watch:{
        'settings':{
            handler:function(val){

                if(val){

                    if(val.subscription){

                        let raw = (val.subscription.usage_space / val.subscription.package.total_space) * 100;

                        this.usagePercentage = Math.round(raw);

                        let totalSpace = (val.subscription.package.total_space / (1024 * 1024));
                        let remainingSpace = (val.subscription.usage_space / (1024 * 1024));

                        this.totalSpace = totalSpace.toFixed(2);
                        this.remainingSpace = remainingSpace.toFixed(2);

                    }

                }

            },
            deep:true
        }
    },
    async beforeMount(){

        if((this.settings.length == 0 || !this.settings)){

            this.$store.dispatch('settings_module/fetchStoreSetting');

        }else{

            let raw = (this.settings.subscription.usage_space / this.settings.subscription.package.total_space) * 100;

            this.usagePercentage = Math.round(raw);

            let totalSpace = (this.settings.subscription.package.total_space / (1024 * 1024));
            let remainingSpace = (this.settings.subscription.usage_space / (1024 * 1024));

            this.totalSpace = totalSpace.toFixed(2);
            this.remainingSpace = remainingSpace.toFixed(2);

        }

    },
    mounted(){
        
    },
}
</script>
<style scoped>
    .sideBarMenu {
        box-shadow: 1px 1px 11px #14223d36;
        height: 100vh;
        /* z-index: 9999; */
        position: fixed;
        overflow-y: auto;
        background: #15223D;
        width: 15%;
        left: 0;
        padding: 24px 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
    }

    .hideSideBar .sideBarMenu {
        left: -5000px;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
    }

    .sideMenuLogo img {
        width: 100%;
    }

    .sideMenuLogo a {
        display: inline-block;
        width: 140px;
    }

    .menuItems {
        margin: 40px 0px;
        max-height: 650px;
        overflow-y: auto;
        padding-right: 5px;
    }
    /* width */
    .menuItems::-webkit-scrollbar {
        width: 2px;
        margin-left: 2px;
    }

    /* Track */
    .menuItems::-webkit-scrollbar-track {
        background: transparent; 
    }
    
    /* Handle */
    .menuItems::-webkit-scrollbar-thumb {
        background: #888; 
    }

    .menuItems ul {
        list-style: none;
        padding: 0px;
        margin: 0px;
    }

    .menuItems ul li {
        margin-bottom: 11px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
    }

    .menuItems ul li:nth-last-child(1) {
        margin-bottom: 0px;
    }

    .menuItems ul li a {
        display: inline-block;
        color: #fff;
        font-size: 15px;
        font-weight: 300;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
    }

    .menuItems ul li:hover a {
        color: #1EC2C2;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
    }

    .menuItems ul li svg {
        float: right;
        width: 13px;
        fill: #fff;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
    }

    .menuItems ul li:hover svg {
        fill: #1EC2C2;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
    }

    /* .router-link-exact-active.router-link-active{
        color: #1ec2c2;
    } */
    .router-link-exact-active.router-link-active{
        color: #1ec2c2 !important;
    }
    /* .router-link-active{
        color: #1ec2c2 !important;
    } */
    /* .menuItems ul .subSideMenu {
    margin-bottom: 15px;
    } */

    .menuItems ul .subSideMenu li a {
        font-size: 15px;
    }

    .subSideMenu {
        padding-left: 46px !important;
        margin-bottom: 20px !important;
    }

    .menuItems ul ul.subSideMenu a {
        position: relative;
    }

    .menuItems ul ul.subSideMenu a::after {
        content: "";
        position: absolute;
        top: 7.5px;
        left: -17px;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background: #1EC2C2;
    }
    .menuItems ul .subSideMenu.submenu_active_1.activeSubMenu {
        margin-bottom: 15px;
        overflow: visible;
        height: auto;
    }

    .menuItems ul .subSideMenu.submenu_active_2.activeSubMenu {
        margin-bottom: 15px;
        overflow: visible;
        height: auto;
    }

    .menuItems ul .subSideMenu.submenu_active_3.activeSubMenu {
        margin-bottom: 15px;
        overflow: visible;
        height: auto;
    }

    .noAccess a{
        color: #808080 !important;
    }

    .userDetailInfo {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .userContact h4 {
        font-size: 17px;
        font-weight: 600;
        color: #fff;
        margin-bottom: 0px;
    }

    .userContact {
        margin-left: 10px;
    }

    .userContact p {
        font-size: 14px;
        font-weight: 600;
        color: #fff;
        margin-bottom: 0px;
    }
    .userContact a {
        font-size: 14px;
        font-weight: 600;
        color: #fff;
        margin-bottom: 0px;
    }
    .userDetailInfo button{
        background-color: transparent;
        padding: 0px;
        border: none;
        border-radius: 0px;
        display: inline-block;
    }
    .userDetailInfo button svg{
        fill: #fff;
        width: 45px;
        height: 33px;
    }
    .userDetailInfoImg{
        width: 50px;
        height: 50px;
        background-color: #fff;
        border-radius: 50%;
        overflow: hidden;
        padding: 5px;
    }
    .userDetailInfoImg img{
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .packageLineBlock p{
        color: #fff;
        font-size: 16px;
        margin-bottom: 4px;
        font-weight: 400;
    }
    .packageTitleCol h5{
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 0px;
        color: #fff;
    }
    .packageButtonCol button{
        color: #fff;
        font-size: 14px;
        font-weight: 400;
        background-color: #FD4D5D;
        padding: 3px 21px;
        border-radius: 0px;
        border: none;
        box-shadow: 0px 5px 0px #bc3340;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
    }
    .packageButtonCol button:hover{
        opacity: 0.8;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
    }
    .packageBtnrow{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 16px;
    }
    .packageLineBlock{
        position: relative;
        padding-bottom: 10px;
        padding-top: 15px;
        border-top: 1px solid #707070;
        margin-bottom: 12px;
    }
    .packageLineBlock::after{
        content: "";
        position: absolute;
        bottom: 0px;
        left: -20px;
        width: 281.09px;
        background-color: #707070;
        height: 1px;
    }
    .packageLine .progressBarRow{
        max-width: 100%;
    }
    .progressBarRow{
        max-width: 452px;
    }
    .progressBarTrack{
        background: #F3F3F3;
        width: 100%;
        height: 10px;
        margin-bottom: 7px;
    }
    .progressBar{
        height: 100%;
        width: 40%;
        background: #1EC2C2;
    }
    .progressBarCtmRow{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .progressBarCtmColumn p{
        font-size: 16px;
        margin-bottom: 0px;
        color: #fff;
        font-weight: 400;
    }

    .sideMenuLogo button.hideSideMenuBtn {
        width: auto;
        background-color: transparent;
        border: none;
        outline: none;
        display: inline-block;
        padding: 0px;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
    }

    .sideMenuLogo button.hideSideMenuBtn svg {
        width: 35px;
        height: 35px;
        fill: #fff;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
    }

    .sideMenuLogo button.hideSideMenuBtn:hover svg {
        fill: #fd4e5d;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
    }
    .sideMenuLogo {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .userDetailInfo .dropdown .dropdown-menu{
        padding: 0px !important;
        overflow: hidden;
    }
    .userDetailInfo .dropdown .dropdown-menu button{
        padding: 6px 15px !important;
        border-bottom: 1px solid #e1e1e1;
    }
    .userDetailInfo .dropdown .dropdown-menu button:nth-last-child(1){
        border-bottom: none;
    }
    .dropdown-item:focus, .dropdown-item:hover {
        color: #16181b;
        text-decoration: none;
        background-color: #e9ecef;
    }
    .menuItems ul li a span{
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 15px;
        margin-right: 10px;
    }
    .menuItems ul li a span svg{
        float: none;
        width: 100%;
        fill: #fff;
        transition: all 0.3s ease-in-out;
    }
    @media(max-width: 1024px){
        .sideBarMenu {
            width: 30%;
            left: -5000px;
            transition: all 0.3s ease-in-out;
        }
    }
</style>