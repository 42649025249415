<template>
    <div class="right-menu shipping-right">

        <subHeader pageName="MARKETING TOOLS" />

        <tutorialsAction />

        <div class="paymentSection">
            <div class="db-container">
                <div class="paymentMainBlock">

                    <!-- Klaviyo starts here-->
                    <div class="paymentMethodWrap" id='klaviyo-step'>
                        <div class="row align-items-center">
                            <div class="col-md-6">
                                <div class="paymentHeading">
                                    <h4>Klaviyo</h4>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="actionSwitch">
                                    <label class="switch" id="klaviyo-status">
                                        <input 
                                            type="checkbox" 
                                            v-model="klaviyo.isActive" 
                                            :disabled="tourIsActive" 
                                            @change="toggleProceedableHandle('klaviyo')"
                                        >
                                        <span class="slider"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <ValidationObserver ref="klaviyoObserver">
                            <div class="bankingFormField" :class="klaviyo.isActive ? 'expandForm' : '' " id="klaviyo-form">
                                <form @submit.prevent="validate('klaviyo')">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <ValidationProvider name="public key" rules="required" v-slot="{ errors }">
                                                <div class="bankInputField">
                                                    <label for="klaviyo-public-key">Public Key <span>*</span></label>
                                                    <input 
                                                        type="text" 
                                                        id="klaviyo-public-key"
                                                        placeholder="Public key" 
                                                        v-model="klaviyoData.publicKey" 
                                                        :disabled="tourIsActive"
                                                    >
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-md-6">
                                            <ValidationProvider name="private key" rules="required" v-slot="{ errors }">
                                                <div class="bankInputField">
                                                    <label for="klaviyo-private-key">Private Key <span>*</span></label>
                                                    <input 
                                                        type="text" 
                                                        id="klaviyo-private-key"
                                                        placeholder="Private key" 
                                                        v-model="klaviyoData.privateKey" 
                                                        :disabled="tourIsActive"
                                                    >
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="paymentSaveBtn">
                                                <loaderBtn v-if="klaviyoLoader"/>
                                                <Button id="klaviyo-save-btn" :disabled="tourIsActive" v-else>Save</Button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </ValidationObserver>
                    </div>
                    <!-- Klaviyo ends here-->

                </div>
            </div>
        </div>

        <!--vue tour-->
        <v-tour name="marketingToolsTour" :steps="tourSteps" :options="tourOptions" :callbacks="tourCallbacks"></v-tour>

    </div>
</template>

<script>
import { MessageBox, Message, Loading } from "element-ui";
import _ from 'lodash';
import { mapGetters } from 'vuex';
import subHeader from "@/components/subHeader.vue";
import tutorialsAction from "@/components/tutorialsAction.vue";
import loaderBtn from "@/components/loaderBtn.vue";

export default {
    metaInfo(){
        return{
            title: 'Marketing | Marketing Tools | YeetCommerce',
        };
    },
    data(){
        return {
            marketingToolsLoader: false,

            //klaviyo start
            klaviyo: {},
            tempKlaviyo: {},
            klaviyoData: {},
            klaviyoLoader: false,
            //klaviyo end

            //vue tour
            tourOptions:{
                useKeyboardNavigation: false,
                labels: {
                    buttonSkip: 'Skip Tour',
                    buttonPrevious: 'Previous',
                    buttonNext: 'Next',
                    buttonStop: 'Finish Tour'
                }
            },
            tourCallbacks:{
                onPreviousStep: this.handlePreviousStep,
                onNextStep: this.handleNextStep,
                // onStart: this.handleTourStart,
                onStop: this.handleTourStop,
                onFinish: this.handleTourFinish,
                onSkip: this.handleTourSkip
            },
            tourSteps:[
                //header steps
                {
                    target: '#tour-start-btn',
                    content: `<strong>Start Tour</strong><br>Provides insights into the available functionalities within a specific menu, helping you understand what actions you can perform.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#master-search',
                    content: `<strong>Master Search</strong><br>Enables you to perform searches from all over the dashboard.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    },
                },
                {
                    target: '#add-new-dropDown',
                    content: `<strong>Add New Record</strong><br>Enables you to add new orders, products and categories on the go.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    }
                },
                {
                    target: '#help-link',
                    content: `<strong>Help</strong><br>If you require any assistance, feel free to reach out to us.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    }
                },
                {
                    target: '#user-options-dropdown',
                    content: `<strong>User Information</strong><br>Presents essential details about you along with a selection of available options.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    }
                },
                //marketing tools steps
                {
                    target: '#klaviyo-step',
                    content: `<strong>Klaviyo</strong><br>Klaviyo configuration is available for the user.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement:"bottom"
                    }
                },
                {
                    target: '#klaviyo-status',
                    content: `<strong>Active/Disabled</strong><br>Status of the Klaviyo can be changed using this option.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement:"bottom"
                    }
                },
                {
                    target: '#klaviyo-public-key',
                    content: `<strong>Klaviyo Public Key</strong><br>Klaviyo public key can be added in this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement:"bottom"
                    }
                },
                {
                    target: '#klaviyo-private-key',
                    content: `<strong>Klaviyo Private Key</strong><br>Klaviyo private key can be added in this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement:"bottom"
                    }
                },
                {
                    target: '#klaviyo-save-btn',
                    content: `<strong>Save Details</strong><br>Klaviyo details can be saved by clicking this button.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement:"bottom"
                    }
                },
            ],
            tourIsActive:false

        }
    },
    components:{
        subHeader,
        tutorialsAction,
        loaderBtn
    },
    computed:{

    },
    methods:{

        validate(form){

            if(form == 'klaviyo'){

                this.$refs.klaviyoObserver.validate().then((success) => {

                    if(!success){

                        const errors = Object.entries(this.$refs.klaviyoObserver.errors)
                        .map(([key, value]) => ({ key, value })).filter((error) => error["value"].length);

                        this.$refs.klaviyoObserver.refs[errors[0]["key"]].$el.scrollIntoView({ behavior: "smooth", block: "center",});

                    }else{

                        this.updateMarketingToolHandle(form);

                    }

                });

            }

        },

        async updateMarketingToolHandle(form){

            let formData = new FormData();

            if(form == 'klaviyo'){

                formData.append('id',this.klaviyo.id);
                formData.append('publicKey',this.klaviyoData.publicKey);
                formData.append('privateKey',this.klaviyoData.privateKey);
                formData.append('isActive',this.klaviyo.isActive ? 1 : 0);

                this.klaviyoLoader = true;

            }

            let loader = Loading.service({
                text: "Fetching marketing tool is being updated. Please wait!",
                fullscreen: true,
            });

            try{
                let res = await this.$axios.post('/marketing-tool/update',formData);
                if(res.data.status_code == "1732"){

                    this.$notify({
                        type: 'success',
                        title: 'Success',
                        message: res.data.message,
                    });

                }
            }catch(error){

                if(error.response){

                    if(error.response.data.error.includes('Unable to confirm')){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.error,
                        });

                    }else{

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.message,
                        });

                    }

                }else{

                    this.$message({
                        type: 'error',
                        showClose: true,
                        message: error.message,
                    });

                }

            }finally{

                loader.close();

                if(form == 'klaviyo'){
                
                    this.klaviyoLoader = false;

                }

                this.loadMarketingToolsData();

            }

        },

        async toggleProceedableHandle(form){
            
            if(form == 'klaviyo'){

                if(this.klaviyo.isActive){
                
                    //validating the klaviyo form
                    this.$refs.klaviyoObserver.validate().then(async (success) => {

                        if(!success){

                            const errors = Object.entries(this.$refs.klaviyoObserver.errors)
                            .map(([key, value]) => ({ key, value })).filter((error) => error["value"].length);

                            this.$refs.klaviyoObserver.refs[errors[0]["key"]].$el.scrollIntoView({behavior: "smooth", block: "center",});

                            this.$notify({
                                type: 'info',
                                title: 'Information Missing',
                                message: "Kindly enter required information in the Klaviyo form and save it to enable it.",
                            });

                            this.$refs.klaviyoObserver.reset();

                        }else{

                            if(this.klaviyo.isActive != this.tempKlaviyo.isActive){

                                this.marketingToolStatusToggle(form);

                            }

                        }

                    });
                    
                }else{

                    if(this.klaviyo.isActive != this.tempKlaviyo.isActive){

                        this.marketingToolStatusToggle(form);

                    }

                }

            }
            
        },
        async marketingToolStatusToggle(form){

            let formData = new FormData();

            if(form == 'klaviyo'){
            
                formData.append('id',this.klaviyo.id);
                formData.append('isActive',this.klaviyo.isActive ? 1 : 0);

            }

            let loader = Loading.service({
                text: "The marketing tool is undergoing status update. Please wait!",
                fullscreen: true,
            });

            try{
                let res = await this.$axios.post('/marketing-tool/toggle',formData);
                if(res.data.status_code == '1600' || res.data.status_code == '1601'){

                    if(form == 'klaviyo'){
                    
                        this.$notify({
                            type: 'success',
                            title: 'Success',
                            message: `Klaviyo has been ${res.data.message}`,
                        });
                    
                    }

                }
            }catch(error){

                this.$message({
                    type: 'error',
                    showClose: true,
                    message: error.response ? error.response.data.message : error.message,
                });

            }finally{

                loader.close();

                this.loadMarketingToolsData();

            }

        },

        async loadMarketingToolsData(){
            
            let loader = Loading.service({
                text: "Fetching marketing tools. Please wait!",
                fullscreen: true,
            });

            try{
                let res = await this.$axios.get('/marketing-tool/all');
                if(res.data.status_code == "1731"){

                    res.data.marketing_tools.forEach((marketingToolData) => {

                        if(marketingToolData.name == 'Klaviyo'){

                            this.klaviyo = marketingToolData;

                            this.tempKlaviyo = _.cloneDeep(this.klaviyo);

                            let marketingTool = JSON.parse(this.klaviyo.data);

                            this.klaviyoData = marketingTool;

                        }

                    });

                }
            }catch(error){

                this.$message({
                    type: 'Error',
                    showClose: true,
                    message: error.response ? error.response.data.message : error.message,
                });
                
            }finally{

                loader.close();

            }
        
        },

        //tour methods
        startMarketingToolsTour(){
        
            this.$tours.marketingToolsTour.start();

            this.tourIsActive = true;
        
            this.klaviyo.isActive = false;

        },
        handleNextStep(currentStep){

            if(currentStep + 1 == 6){

                this.klaviyo.isActive = true;

            }

        },
        handlePreviousStep(currentStep){

            if(currentStep == 6){

                this.klaviyo.isActive = false;

            }

        },
        handleTourStop(){

            if(this.$tours.marketingToolsTour && this.$tours.marketingToolsTour.currentStep != -1){
                
                this.loadMarketingToolsData();

                this.tourIsActive = false;

            }

        },
        handleTourFinish(){

            this.loadMarketingToolsData();

            this.tourIsActive = false;

        },
        handleTourSkip(){

            if(this.$tours.marketingToolsTour && this.$tours.marketingToolsTour.currentStep != -1){
                
                this.loadMarketingToolsData();

                this.tourIsActive = false;

            }

        },

    },
    beforeMount(){

        //loading the marketing tools data
        this.loadMarketingToolsData();

    },
}
</script>

<style scoped>
    .bankingFormField .row{
        margin-left: 0px !important;
        margin-right: 0px !important;
    }
</style>