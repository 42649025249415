<template>
    <div>
        <div class="right-menu shipping-right">

            <subHeader pageName="NOTIFICATIONS"/>

            <tutorialsAction />

            <div class="db_top">
                <div class="db-container">

                    <moduleStats/>

                    <!-- search block starts here -->
                    <moduleSearch :moduleName="'notification'" :disable="tourIsActive" ref="module_search"/>
                    <!-- search block ends here -->

                    <!-- data table starts here -->
                    <div class="tableMainWrapBox" style="margin-top: 25px;position: relative;" id="preview-record">
                        <div class="tableScrollWrap" ref="notificationsTable">
                            <table>
                                <thead>
                                    <tr>
                                        <th>
                                            <div class="ctmCheckWrap">
                                                <label class="ctmTableCheckContainer">
                                                    <input 
                                                        type="checkbox"
                                                        @click="toggleIsCheckedAll()"
                                                        :checked="checkedItems.length != 0 && checkedItems.length == notificationsList.length"
                                                        :disabled="tourIsActive || this.previewForm.id != ''"
                                                    >
                                                    <span class="checkmark"></span>
                                                </label>
                                            </div>
                                        </th>
                                        <th class="ctmPr-3">
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Notification Subject">Subject</p>
                                            </div>
                                        </th>
                                        <th class="ctmPr-3">
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Sent At">Sent At</p>
                                            </div>
                                        </th>
                                        <th>
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Action">Action</p>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody v-if="notificationsLoading">
                                    <tr v-for="i in 5" :key="i">
                                        <td>
                                            <div class="ctmCheckWrap">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="20"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="200"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="200"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="200"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr v-if="notificationsList.length == 0">
                                        <td colspan="5" style="text-align:center">
                                            {{ notificationsSearchedKeyword ? `No notifications found matching '${notificationsSearchedKeyword}'` : 'No Notifications Available' }}
                                        </td>
                                    </tr>

                                    <tr v-for="(listedNotification,index) in notificationsList" :key="index">

                                        <!-- checkbox block starts here -->
                                        <td @click="previewNotification(listedNotification,index,'input',$event)">
                                            <div class="ctmCheckWrap" data-open="true">
                                                <label 
                                                    class="ctmTableCheckContainer" 
                                                    data-open="false" 
                                                    @click.prevent=" listedNotification.id != -1 ? checkedItemHandle(listedNotification.id) : '' "
                                                >
                                                    <input 
                                                        type="checkbox" 
                                                        v-model="checkedItems" 
                                                        :value="listedNotification.id" 
                                                        :disabled="tourIsActive || listedNotification.id == -1" 
                                                        data-open="false"
                                                    >
                                                    <span class="checkmark" data-open="false"></span>
                                                </label>
                                            </div>
                                        </td>
                                        <!-- checkbox block starts here -->

                                        <!--subject block starts here -->
                                        <td @click="previewNotification(listedNotification,index,'solid',$event)">

                                            <div class="tableBodyCtmTxt" data-open="true">
                                                <div 
                                                    class="editFieldTitle" 
                                                    data-open="true"
                                                    id="table-subject-field" 
                                                    v-if="(listedNotification.id == previewForm.id) && (previewForm.id == -1)"
                                                >
                                                    <input 
                                                        type="text" 
                                                        placeholder="Write a notification subject"
                                                        v-model="listedNotification.subject" 
                                                        :disabled="tourIsActive"
                                                        data-open="false"
                                                    >
                                                </div>
                                                <p data-open="true" v-else>
                                                    {{ 
                                                        listedNotification.subject ? listedNotification.subject.length > 40 ? 
                                                        listedNotification.subject.substr(0, 40) + "..." : listedNotification.subject : '--'
                                                    }}
                                                </p>
                                            </div>
                                        </td>
                                        <!--subject block ends here -->

                                        <!--subject block starts here -->
                                        <td @click="previewNotification(listedNotification,index,'solid',$event)">
                                            <div class="tableBodyCtmTxt" data-open="true">
                                                <p data-open="true">
                                                    {{ listedNotification.created_at ? new Date(listedNotification.created_at).toLocaleString("en-US",{year: 'numeric'}) + "-"+
                                                        new Date(listedNotification.created_at).toLocaleString("en-US",{month: 'numeric'}) + "-"+
                                                        new Date(listedNotification.created_at).toLocaleString("en-US",{day: 'numeric'})
                                                        + ' | '+ new Date(listedNotification.created_at).toLocaleTimeString('en-US') : '--' 
                                                    }}
                                                </p>
                                            </div>
                                        </td>
                                        <!--subject block ends here -->

                                        <!-- action block starts here -->
                                        <td @click.prevent="previewNotification(listedNotification,index,'input',$event)">
                                            <div class="ctmDataTableActionBtn" v-if="isDeletingItem(listedNotification.id) && deleteLoader" data-open="false">
                                                <loaderBtn />
                                            </div>
                                            <div class="ctmDataTableActionBtn" data-open="true" v-else>
                                                <button class="delFunction" @click.prevent="deleteNotificationHandle(listedNotification,index)" :disabled="tourIsActive" data-open="false">
                                                    <i aria-hidden="true" class="fa fa-trash-o" data-open="false"></i>
                                                </button>
                                            </div>
                                        </td>
                                        <!-- action block ends here -->

                                    </tr>

                                    <!-- load more block starts here -->
                                    <tr v-if="isPending">
                                        <td>
                                            <div class="ctmCheckWrap">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="20"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="200"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="200"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="200"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <!-- load more block ends here -->

                                </tbody>
                            </table>
                        </div>

                        <!-- add/update account form starts here -->
                        <div class="productSlideBox" ref="viewInputForm" :class="previewForm.id ? 'activeRow': ''" >
                            <div class="tableActionBtns" id="notification-details-preview">
                                <div class="productTableSideBar">
                                    <div class="row">
                                        <div class="col-md-8" id="notification-subject-field-2">
                                            <div class="viewOrderTitle">
                                                <input 
                                                    type="text" 
                                                    placeholder="Write a notification subject" 
                                                    v-model="previewForm.subject" 
                                                    :disabled="tourIsActive"
                                                >
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="orderViewNav">
                                                <ul>
                                                    <el-tooltip class="box-item" effect="dark" content="Expand" placement="top-start">
                                                        <li class="sm-extand-hide">
                                                            <button @click.prevent="expand" :disabled="tourIsActive" id="expand-form">
                                                                <svg viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg">
                                                                    <g fill="none" fill-rule="evenodd" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" transform="translate(2 2)">
                                                                        <path d="m16.5 5.5v-4.978l-5.5.014"/>
                                                                        <path d="m16.5.522-6 5.907"/>
                                                                        <path d="m11 16.521 5.5.002-.013-5.5"/>
                                                                        <path d="m16.5 16.429-6-5.907"/>
                                                                        <path d="m.5 5.5v-5h5.5"/>
                                                                        <path d="m6.5 6.429-6-5.907"/>
                                                                        <path d="m6 16.516-5.5.007v-5.023"/>
                                                                        <path d="m6.5 10.5-6 6"/>
                                                                    </g>
                                                                </svg>
                                                            </button>
                                                        </li>
                                                    </el-tooltip>
                                                    <el-tooltip class="box-item" effect="dark" content="Close" placement="top-start">
                                                        <li>
                                                            <button @click.prevent="closePreview" id="closeSide" :disabled="tourIsActive">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="29.446" height="33.409" viewBox="0 0 29.446 33.409">
                                                                    <g id="Group_20906" data-name="Group 20906" transform="translate(362.041 -2185.5)">
                                                                        <path id="Path_57522" data-name="Path 57522" d="M-361.041,2186.648v31.409" transform="translate(0 -0.148)" fill="none" stroke="#000" stroke-linecap="round" stroke-width="2"/>
                                                                        <g id="Group_20905" data-name="Group 20905" transform="translate(0 -0.5)">
                                                                            <path id="Path_57523" data-name="Path 57523" d="M-354.018,2202.5h20.265" transform="translate(0 0)" fill="none" stroke="#000" stroke-linecap="round" stroke-width="2"/>
                                                                            <path id="Path_57524" data-name="Path 57524" d="M-342.6,2193.747l8,9.15-8,8.85" transform="translate(1 -0.247)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                                                        </g>
                                                                    </g>
                                                                </svg>
                                                            </button>
                                                        </li>
                                                    </el-tooltip>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="sectionTabsLink">
                                    <ul onclick="window.myFunction(event)">
                                        <li v-if="!tourIsActive">
                                            <a class="active">Notification Info</a>
                                        </li>
                                    </ul>
                                    <div class="updateBtnBox" v-if="notificationIsSaving">
                                        <loaderBtn/>
                                    </div>
                                    <div class="updateBtnBox" id="save-notification-btn" v-else>
                                        <button 
                                            form="notification-information" 
                                            button="submit" 
                                            @click="validate()" 
                                            :disabled="tourIsActive" 
                                            v-if="previewForm.id == -1"
                                        >
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <ValidationObserver ref="notificationFormObserver">
                                <div class="productTableInfoBlock">
                                    <div class="productTableInfoWhiteBox">
                                        <form id="notification-information" @submit.prevent="validate()">
                                            <div class="tableFromFieldBox" id="notification-subject">
                                                <label for="notification-subject-field">Subject<span>*</span></label>
                                                <ValidationProvider name="subject" :rules="{ required: true, max:50 }" v-slot="{ errors }">
                                                    <div class="tableFromFieldItem">
                                                        <input 
                                                            type="text" 
                                                            name="notification-subject-field" 
                                                            id="notification-subject-field" 
                                                            placeholder="Subject"
                                                            v-model="previewForm.subject" 
                                                            :disabled="tourIsActive || previewForm.id != -1"
                                                        >
                                                    </div>
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </div>
                                            <div class="tableFromFieldBox" id="notification-recipients">
                                                <label for="notification-recipients-field">Recipients<span>*</span></label>
                                                <ValidationProvider name="recipients" :rules="{ required: true }" v-slot="{ errors }">
                                                    <div class="vueSelectBlock">
                                                        <Multiselect
                                                            v-model="previewForm.recipientsList"
                                                            :options="options"
                                                            :multiple="true"
                                                            group-values="emails"
                                                            group-label="selectAll"
                                                            :group-select="true"
                                                            placeholder="Recipients"
                                                            track-by="email"
                                                            label="email"
                                                            :disabled="tourIsActive || previewForm.id != -1"
                                                            :searchable="true"
                                                            @search-change="loadCustomerEmails"
                                                            :loading="optionsLoading"
                                                        />
                                                    </div>
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </div>
                                            <div class="tableFromFieldBox" id="notification-body">
                                                <label for="notification-body-field">Body<span>*</span></label>
                                                <ValidationProvider name="body" :rules="{ required: true }" v-slot="{ errors }">
                                                    <div class="tableFromFieldItem">
                                                        <quill-editor  
                                                            name="comment"
                                                            :options="editorOptions"
                                                            v-model="previewForm.body"
                                                            :disabled="tourIsActive || previewForm.id != -1"
                                                        >
                                                        </quill-editor>
                                                    </div>
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </ValidationObserver>
                        </div>
                        <!-- add/update account form ends here -->

                    </div>
                    <!-- data table ends here -->

                    <!-- bottom action bar starts here -->
                    <div class="row product-bottom-bar m-0" v-if="checkedItems.length > 1">
                        <div class="col-md-6 model-footer-main1">
                            <div class="bottom-bar">
                                <a href="" class="model-footer-1 mr-5" @click.prevent="toggleIsCheckedAll()" v-if="checkedItems.length != notificationsList.length">
                                    <i class="fa fa-check-square-o" aria-hidden="true"></i>
                                    <p>SELECT ALL</p>
                                </a>
                                <a href="" class="model-footer-1" @click.prevent="clearSelection()">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.026 14.027">
                                        <path id="cross-small" d="M18.969,6.023h0a1.079,1.079,0,0,0-1.525,0L12.5,10.971,7.549,6.023a1.079,1.079,0,0,0-1.525,0h0a1.079,1.079,0,0,0,0,1.525L10.971,12.5,6.023,17.443a1.079,1.079,0,0,0,0,1.525h0a1.079,1.079,0,0,0,1.525,0L12.5,14.021l4.947,4.947a1.079,1.079,0,0,0,1.525,0h0a1.079,1.079,0,0,0,0-1.525L14.021,12.5l4.947-4.947A1.079,1.079,0,0,0,18.969,6.023Z" transform="translate(-5.483 -5.483)" fill="#fd4d5d" stroke="#fd4d5d" stroke-width="0.3"/>
                                    </svg>
                                    <p>CLEAR SELECTION</p>
                                </a>
                            </div>
                            <div class="model-footer-2">{{ checkedItems.length }}</div>
                        </div>
                        <div class="col-md-6 model-footer-main1 product-row-reverse">
                            <div class="bottom-bar">
                                <a href="" class="model-footer-1 ml-4" @click.prevent="notificationsBulkActionHandle('delete')">
                                    <i class="fa fa-trash-o" aria-hidden="true"></i>
                                    <p>DELETE</p>
                                </a>
                            </div>
                        </div>
                    </div>
                    <!-- bottom action bar ends here -->

                </div>
            </div>

        </div>

        <!--vue tour-->
        <v-tour name="notificationsTour" :steps="tourSteps" :options="tourOptions" :callbacks="tourCallbacks"></v-tour>

    </div>
</template>

<script>
import { MessageBox, Message, Loading } from "element-ui";
import subHeader from "@/components/subHeader.vue";
import Multiselect from 'vue-multiselect'
import _ from 'lodash';
import { mapGetters } from 'vuex';
import tutorialsAction from "@/components/tutorialsAction.vue";
import moduleStats from "@/components/moduleStats.vue";
import loaderBtn from "@/components/loaderBtn.vue";
import moduleSearch from "@/components/ModuleSearch/index.vue";

export default {
    metaInfo() {
        return {
            title: 'Notifications | YeetCommerce',
        };
    },
    data(){
        return {

            //notifications start
            notificationsList:[],
            notificationsLoading:false,
            notificationSaved:false,
            notificationIsSaving:false,
            previewForm:{
                id:'',
                subject:'',
                body:'',
                recipients:[],
                recipientsList:[],
                isTouched:false,
            },
            defaultPreviewForm:{
                id:'',
                subject:'',
                body:'',
                recipients:[],
                recipientsList:[],
                isTouched:false,
            },
            tempPreviewNotification:{
                id:'',
                subject:'',
                body:'',
                recipients:[],
                recipientsList:[],
                isTouched:false,
            },
            options: [
                {
                    selectAll: 'Select All',
                    emails: []
                },
            ],
            optionsLoading: false,
            optionsTimer: undefined,
            editorOptions:{
                placeholder: 'Body',
                theme: 'snow',
                modules:{
                    toolbar:{
                        container:[ 
                            [{ "font": [] },],
                            [{'header': [1, 2, 3, 4, 5, 6, false]}],
                            ["blockquote", "code-block"],
                            ['bold', 'italic', 'underline', 'strike'],
                            ["clean"],
                            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                            [{ 'script': 'sub'}, { 'script': 'super' }],
                            [{'color': []}, {'background': []}],
                            [{ 'direction': 'rtl' }, { 'align': [] }],
                        ],
                    }
                }
            },
            //notifications end

            //checked items start
            checkedItems:[],
            deletingItems:[],
            deleteLoader:false,
            isMasterSearched:false,
            //checked items end

            //tour start
            tourOptions: {
                useKeyboardNavigation: false,
                labels: {
                    buttonSkip: 'Skip Tour',
                    buttonPrevious: 'Previous',
                    buttonNext: 'Next',
                    buttonStop: 'Finish Tour'
                }
            },
            tourCallbacks: {
                onPreviousStep: this.handlePreviousStep,
                onNextStep: this.handleNextStep,
                // onStart: this.handleTourStart,
                onStop: this.handleTourStop,
                onFinish: this.handleTourFinish,
                onSkip: this.handleTourSkip
            },
            tourSteps:[
                //header steps
                {
                    target: '#tour-start-btn',
                    content: `<strong>Start Tour</strong><br>Provides insights into the available functionalities within a specific menu, helping you understand what actions you can perform.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#master-search',
                    content: `<strong>Master Search</strong><br>Enables you to perform searches from all over the dashboard.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    },
                },
                {
                    target: '#add-new-dropDown',
                    content: `<strong>Add New Record</strong><br>Enables you to add new orders, products and categories on the go.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    }
                },
                {
                    target: '#help-link',
                    content: `<strong>Help</strong><br>If you require any assistance, feel free to reach out to us.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    }
                },
                {
                    target: '#user-options-dropdown',
                    content: `<strong>User Information</strong><br>Presents essential details about you along with a selection of available options.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    }
                },
                //notifications steps
                {
                    target: '#module-search',
                    content: `<strong>Search Notification</strong><br>It enables you to search specific notifications.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    }
                },
                {
                    target: '#preview-record',
                    content: `<strong>Notifications Table</strong><br>You can preview the added notifications by clicking on each individual record present in the table.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#add-new-record',
                    content: `Add an <strong>Notification</strong><br>By clicking this button, you can add a new notification and open a form to input and save the details.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#notification-details-preview',
                    content: `<strong>Notification Details Form</strong><br>This form can be used to add details about the notification and save them.`,
                    params: {
                        highlight: false,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#notification-subject-field',
                    content: `<strong>Notification Subject Field</strong><br>You can add the subject of notification using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#notification-recipients',
                    content: `<strong>Notification Recipients Field</strong><br>You can add the recipients of notification using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#notification-body',
                    content: `<strong>Notification Body Field</strong><br>You can add the body of notification using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#table-subject-field',
                    content: `<strong>Notification Subject Field</strong><br>You can also add the subject of notification using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#notification-subject-field-2',
                    content: `<strong>Notification Subject Field</strong><br>You can also add the subject of notification using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#expand-form',
                    content: `<strong>Expand Form</strong><br>Form can be expanded or collapsed by clicking this button.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'left'
                    }
                },
                {
                    target: '#closeSide',
                    content: `<strong>Close Form</strong><br>Form can be closed using this button.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'left'
                    }
                },
                {
                    target: '#save-notification-btn',
                    content: `<strong>Save Notification Details</strong><br>Notification can be sent by clicking this button.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'left'
                    }
                },
            ],
            tourIsActive:false,
            //tour end

        }
    },
    components:{
        subHeader,
        tutorialsAction,
        moduleStats,
        loaderBtn,
        Multiselect,
        moduleSearch,
    },
    computed:{
        ...mapGetters({
            settings:'settings_module/settings',
            storeNotifications:'notifications_module/notifications',
            notificationsCurrentPage: 'notifications_module/currentPage',
            notificationsLastPage: 'notifications_module/lastPage',
            notificationsSearchedKeyword:'notifications_module/keyword',
            isPending:'notifications_module/isPending',
        }),
    },
    watch:{
        'storeNotifications':{
            handler:function(value){

                this.notificationsLoading = false;

                if(this.previewForm.id == -1){
        
                    this.notificationsList = _.cloneDeep(value);
                    
                    this.notificationsList.unshift(this.previewForm);

                }else if(this.tempPreviewNotification.id != ''){

                    this.notificationsList = _.cloneDeep(value);

                    this.notificationsList[this.previewForm.index] = this.previewForm;

                }else{

                    this.notificationsList = _.cloneDeep(value);

                }

                if(this.isMasterSearched){

                    this.previewSearchedNotification(this.notificationsList[0],0);

                    this.isMasterSearched = false;

                }

            },deep:true
        },
        'previewForm':{
            handler:function(value){

                if((value.id == -1) && (value.subject || value.body || (value.recipientsList.length > 0))){

                    value.isTouched = true;

                }else{

                    value.isTouched = false;

                }

            },deep:true
        },
    },
    methods:{
        //infinite scroll start
        handleScrollEvent(event){

            let element = this.$refs.notificationsTable;

            let maxScrollTop = element.scrollHeight - element.clientHeight;

            if(element.scrollTop >= maxScrollTop - 100){

                if(this.isPending == false && this.notificationsCurrentPage != this.notificationsLastPage){

                    this.$store.dispatch('notifications_module/fetchStoreNotificationsRequest',{size: 20, page: this.notificationsCurrentPage + 1, loadData: 'more'});
                
                }

            }

        },
        //infinite scroll end

        //preview form open/close & reset start
        previewNotification(listedNotification,i,field,e){

            if(field == 'input'){

                if((e.target.tagName == "TD" || e.target.dataset.open == "true") && !this.tourIsActive){

                    if(listedNotification.id != -1){

                        if(this.previewForm.id == -1){

                            MessageBox.confirm(
                                "Are you sure? unsaved changes will be discarded", 
                                "Warning", 
                                {
                                    type: "warning",
                                    confirmButtonText: "Yes",
                                    cancelButtonText: "Cancel",
                                }
                            ).then(()=>{

                                let index = this.notificationsList.findIndex((notification) => notification.id == -1);
                                
                                if(index != -1){
                    
                                    this.notificationsList.splice(index, 1);
                    
                                }

                                if(!this.notificationSaved){

                                    let tempIndex = this.notificationsList.findIndex(x=>x.id == this.tempPreviewNotification.id);

                                    if(tempIndex != -1){

                                        this.notificationsList[tempIndex] = this.tempPreviewNotification;

                                    }

                                }

                                let notificaion = listedNotification;

                                this.previewForm = notificaion;
                                this.previewForm.index = i;

                                let recipients = [];

                                listedNotification.recipients.forEach(recipient => {

                                    recipients.push(recipient.user);

                                });

                                this.previewForm.recipientsList = recipients;

                                if(this.previewForm.recipientsList.length > 0){

                                    let recipientsNotListed = [];

                                    this.previewForm.recipientsList.forEach((recipient) => {

                                        if(!this.options[0].emails.some((listedEmail) => listedEmail.id == recipient.id)){

                                            recipientsNotListed.push(recipient);

                                        }

                                    });

                                    if(recipientsNotListed.length > 0){

                                        this.loadCustomerEmails(null);

                                    }

                                }

                                this.checkedItems = [];

                                this.notificationIsSaving = false;

                                this.tempPreviewNotification = _.cloneDeep(notificaion);

                            }).catch(() => {})

                        }else{

                            let index = this.notificationsList.findIndex((notification) => notification.id == -1);
                                
                            if(index != -1){
                
                                this.notificationsList.splice(index, 1);
                
                            }

                            if(!this.notificationSaved && (this.tempPreviewNotification.id != '' && this.tempPreviewNotification.id != listedNotification.id)){
                                
                                let tempIndex = this.notificationsList.findIndex(x=>x.id == this.tempPreviewNotification.id);
                
                                if(tempIndex != -1){

                                    this.notificationsList[tempIndex] = this.tempPreviewNotification;

                                }

                            }

                            if(this.tempPreviewNotification.id != '' && this.tempPreviewNotification.id == listedNotification.id){

                                let tempIndex = this.notificationsList.findIndex(x=>x.id == this.tempPreviewNotification.id);

                                this.notificationsList[tempIndex] = _.cloneDeep(this.storeNotifications[tempIndex]);

                                let notification = _.cloneDeep(this.notificationsList[tempIndex]);

                                this.previewForm = this.notificationsList[tempIndex];
                                this.previewForm.index = i;

                                let recipients = [];

                                listedNotification.recipients.forEach(recipient => {

                                    recipients.push(recipient.user);

                                });

                                this.previewForm.recipientsList = recipients;


                                if(this.previewForm.recipientsList.length > 0){

                                    let recipientsNotListed = [];

                                    this.previewForm.recipientsList.forEach((recipient) => {

                                        if(!this.options[0].emails.some((listedEmail) => listedEmail.id == recipient.id)){

                                            recipientsNotListed.push(recipient);

                                        }

                                    });

                                    if(recipientsNotListed.length > 0){

                                        this.loadCustomerEmails(null);

                                    }

                                }

                                this.checkedItems = [];

                                this.notificationIsSaving = false;

                                this.tempPreviewNotification = _.cloneDeep(notification);

                            }else{

                                let notification = listedNotification;

                                this.previewForm = notification;
                                this.previewForm.index = i;

                                let recipients = [];

                                listedNotification.recipients.forEach(recipient => {

                                    recipients.push(recipient.user);

                                });

                                this.previewForm.recipientsList = recipients;

                                
                                if(this.previewForm.recipientsList.length > 0){

                                    let recipientsNotListed = [];

                                    this.previewForm.recipientsList.forEach((recipient) => {

                                        if(!this.options[0].emails.some((listedEmail) => listedEmail.id == recipient.id)){

                                            recipientsNotListed.push(recipient);

                                        }

                                    });

                                    if(recipientsNotListed.length > 0){

                                        this.loadCustomerEmails(null);

                                    }

                                }

                                this.checkedItems = [];

                                this.notificationIsSaving = false;

                                this.tempPreviewNotification = _.cloneDeep(notification);

                            }

                        }

                    }

                }

            }else{

                if(!this.tourIsActive){
                
                    if(listedNotification.id != -1){

                        if(this.previewForm.id == -1){

                            MessageBox.confirm(
                                "Are you sure? unsaved changes will be discarded", 
                                "Warning", 
                                {
                                    type: "warning",
                                    confirmButtonText: "Yes",
                                    cancelButtonText: "Cancel",
                                }
                            ).then(()=>{

                                let index = this.notificationsList.findIndex((notification) => notification.id == -1);
                                
                                if(index != -1){
                    
                                    this.notificationsList.splice(index, 1);
                    
                                }

                                if(!this.notificationSaved){

                                    let tempIndex = this.notificationsList.findIndex(x=>x.id == this.tempPreviewNotification.id);

                                    if(tempIndex != -1){

                                        this.notificationsList[tempIndex] = this.tempPreviewNotification;

                                    }

                                }

                                let notificaion = listedNotification;

                                this.previewForm = notificaion;
                                this.previewForm.index = i;

                                let recipients = [];

                                listedNotification.recipients.forEach(recipient => {

                                    recipients.push(recipient.user);

                                });

                                this.previewForm.recipientsList = recipients;

                                
                                if(this.previewForm.recipientsList.length > 0){

                                    let recipientsNotListed = [];

                                    this.previewForm.recipientsList.forEach((recipient) => {

                                        if(!this.options[0].emails.some((listedEmail) => listedEmail.id == recipient.id)){

                                            recipientsNotListed.push(recipient);

                                        }

                                    });

                                    if(recipientsNotListed.length > 0){

                                        this.loadCustomerEmails(null);

                                    }

                                }

                                this.checkedItems = [];

                                this.notificationIsSaving = false;

                                this.tempPreviewNotification = _.cloneDeep(notificaion);

                            }).catch(() => {})

                        }else{

                            let index = this.notificationsList.findIndex((notification) => notification.id == -1);
                                
                            if(index != -1){
                
                                this.notificationsList.splice(index, 1);
                
                            }

                            if(!this.notificationSaved && (this.tempPreviewNotification.id != '' && this.tempPreviewNotification.id != listedNotification.id)){
                                
                                let tempIndex = this.notificationsList.findIndex(x=>x.id == this.tempPreviewNotification.id);
                                
                                if(tempIndex != -1){

                                    this.notificationsList[tempIndex] = this.tempPreviewNotification;

                                }
                
                            }

                            if(this.tempPreviewNotification.id != '' && this.tempPreviewNotification.id == listedNotification.id){

                                let tempIndex = this.notificationsList.findIndex(x=>x.id == this.tempPreviewNotification.id);

                                this.notificationsList[tempIndex] = _.cloneDeep(this.storeNotifications[tempIndex]);

                                let notificaion = _.cloneDeep(this.notificationsList[tempIndex]);

                                this.previewForm = this.notificationsList[tempIndex];
                                this.previewForm.index = i;

                                let recipients = [];

                                listedNotification.recipients.forEach(recipient => {

                                    recipients.push(recipient.user);

                                });

                                this.previewForm.recipientsList = recipients;

                                
                                if(this.previewForm.recipientsList.length > 0){

                                    let recipientsNotListed = [];

                                    this.previewForm.recipientsList.forEach((recipient) => {

                                        if(!this.options[0].emails.some((listedEmail) => listedEmail.id == recipient.id)){

                                            recipientsNotListed.push(recipient);

                                        }

                                    });

                                    if(recipientsNotListed.length > 0){

                                        this.loadCustomerEmails(null);

                                    }

                                }

                                this.checkedItems = [];

                                this.notificationIsSaving = false;

                                this.tempPreviewNotification = _.cloneDeep(notificaion);

                            }else{

                                let notificaion = listedNotification;

                                this.previewForm = notificaion;
                                this.previewForm.index = i;

                                let recipients = [];

                                listedNotification.recipients.forEach(recipient => {

                                    recipients.push(recipient.user);

                                });

                                this.previewForm.recipientsList = recipients;

                                
                                if(this.previewForm.recipientsList.length > 0){

                                    let recipientsNotListed = [];

                                    this.previewForm.recipientsList.forEach((recipient) => {

                                        if(!this.options[0].emails.some((listedEmail) => listedEmail.id == recipient.id)){

                                            recipientsNotListed.push(recipient);

                                        }

                                    });

                                    if(recipientsNotListed.length > 0){

                                        this.loadCustomerEmails(null);

                                    }

                                }

                                this.checkedItems = [];

                                this.notificationIsSaving = false;

                                this.tempPreviewNotification = _.cloneDeep(notificaion);

                            }

                        }

                    }
                
                }

            }

        },
        previewSearchedNotification(listedNotification,i){

            let index = this.notificationsList.findIndex((notification) => notification.id == -1);
            
            if(index != -1){

                this.notificationsList.splice(index, 1);

            }

            if(!this.notificationSaved){

                let tempIndex = this.notificationsList.findIndex(x=>x.id == this.tempPreviewNotification.id);

                if(tempIndex != -1){

                    this.notificationsList[tempIndex] = this.tempPreviewNotification;

                }

            }

            let notificaion = listedNotification;

            this.previewForm = notificaion;
            this.previewForm.index = i;

            let recipients = [];

            listedNotification.recipients.forEach(recipient => {

                recipients.push(recipient.user);

            });

            this.previewForm.recipientsList = recipients;

            if(this.previewForm.recipientsList.length > 0){

                let recipientsNotListed = [];

                this.previewForm.recipientsList.forEach((recipient) => {

                    if(!this.options[0].emails.some((listedEmail) => listedEmail.id == recipient.id)){

                        recipientsNotListed.push(recipient);

                    }

                });

                if(recipientsNotListed.length > 0){

                    this.loadCustomerEmails(null);

                }

            }

            this.checkedItems = [];

            this.notificationIsSaving = false;

            this.tempPreviewNotification = _.cloneDeep(notificaion);

        },
        closePreview(){

            if(this.previewForm.isTouched){

                MessageBox.confirm(
                    "Do you want to close? Entered information will be discarded.", 
                    "Warning", 
                    {
                        type: "warning",
                        confirmButtonText: "Yes",
                        cancelButtonText: "Cancel",
                    }
                ).then(async () => {

                    let index = this.notificationsList.findIndex((notification) => notification.id == -1);
                                
                    if(index != -1){
            
                        this.notificationsList.splice(index, 1);
            
                    }
            
                    if(!this.notificationSaved){
            
                        let tempIndex = this.notificationsList.findIndex(x=>x.id == this.tempPreviewNotification.id);
                        
                        if(tempIndex != -1){

                            this.notificationsList[tempIndex] = this.tempPreviewNotification;

                        }

                    }
                    
                    this.previewForm.id = '';

                    // this.previewForm = _.cloneDeep(this.defaultPreviewForm);
                    
                    this.notificationSaved = false;
    
                    this.tempPreviewNotification = _.cloneDeep(this.defaultPreviewForm);

                }).catch(() => {})

            }else{

                let index = this.notificationsList.findIndex((notification) => notification.id == -1);
                                
                if(index != -1){
        
                    this.notificationsList.splice(index, 1);
        
                }
        
                if(!this.notificationSaved){
        
                    let tempIndex = this.notificationsList.findIndex(x=>x.id == this.tempPreviewNotification.id);
                    
                    if(tempIndex != -1){

                        this.notificationsList[tempIndex] = this.tempPreviewNotification;

                    }
        
                }

                this.previewForm.id = '';

                // this.previewForm = _.cloneDeep(this.defaultPreviewForm);
                
                this.notificationSaved = false;

                this.tempPreviewNotification = _.cloneDeep(this.defaultPreviewForm);

            }

        },
        resetPreview(){

            let index = this.notificationsList.findIndex((notification) => notification.id == -1);
                                
            if(index != -1){
    
                this.notificationsList.splice(index, 1);
    
            }

            this.previewForm.id = '';

            // this.previewForm = _.cloneDeep(this.defaultPreviewForm);

            this.notificationSaved = false;

            this.tempPreviewNotification = _.cloneDeep(this.defaultPreviewForm);

        },
        createNotification(){

            if(this.notificationsLoading){

                this.$notify({
                    type: "info",
                    title: "Please Wait",
                    message: "Notifications are being retrieved.",
                });

                return

            }

            if(this.notificationIsSaving){

                this.$notify({
                    type: "info",
                    title: "Please Wait",
                    message: "A notification is currently being processed.",
                });

                return

            }

            let index = this.notificationsList.findIndex(x=>x.id == -1);

            if(index != -1){

                // this.$notify({
                //   type: "error",
                //   title: "Can't Add New Notification",
                //   message: "you have an unsaved notification in the list",
                // });

                return
                
            }

            this.closePreview();

            this.loadCustomerEmails(null);

            this.previewForm = _.cloneDeep(this.defaultPreviewForm);

            this.previewForm.id = -1;

            let tempNotification = this.previewForm;

            this.notificationsList.unshift(tempNotification);

            this.checkedItems = [];

            this.$refs.notificationFormObserver.reset();

        },
        //preview form open/close & reset end

        //add & delete notification start
        validate(){

            this.$refs.notificationFormObserver.validate().then((success) => {

                if(!success){

                    const errors = Object.entries(this.$refs.notificationFormObserver.errors)
                    .map(([key, value]) => ({ key, value })).filter((error) => error["value"].length);

                    this.$refs.notificationFormObserver.refs[errors[0]["key"]].$el.scrollIntoView({ behavior: "smooth", block: "center" });

                }else{

                    if(this.previewForm.id == -1){

                        this.addNotificationHandle();

                    }

                }

            });

        },
        async addNotificationHandle(){

            let formData = new FormData();

            for(let i=0 ; i< this.previewForm.recipientsList.length ; i++){

                formData.append(`recipients[${i}][id]`,this.previewForm.recipientsList[i].id);
                formData.append(`recipients[${i}][email]`,this.previewForm.recipientsList[i].email );

            }

            formData.append('subject',this.previewForm.subject);
            formData.append('body',this.previewForm.body);

            this.notificationIsSaving = true;
            try{
                let res = await this.$axios.post('/email-notification/add',formData);
                if(res.data.status_code == "1240"){

                    this.$notify({
                        type: 'success',
                        title: 'Success',
                        message: res.data.message,
                    });

                    this.$store.commit('notifications_module/add_notifications_list',res.data.notification);

                    this.resetPreview();

                }
            }catch(error){

                this.$message({
                    type: 'error',
                    showClose: true,
                    message: error.response ? error.response.data.message : error.message,
                });

            }finally{

                this.notificationIsSaving = false;

            }

        },
        deleteNotificationHandle(listedNotification,index){

            if(!this.tourIsActive){

                MessageBox.confirm(
                    "Are you sure you want to delete the expense?", 
                    "Warning", 
                    {
                        confirmButtonText: "Yes",
                        cancelButtonText: "Cancel",
                        type: "warning",
                    }
                ).then(async () => {

                    this.deletingItems.push(listedNotification.id);
                    this.deleteLoader = true;

                    let loader = Loading.service({
                        text: "The notification is being removed. Please wait!",
                        fullscreen: true,
                    });

                    try{
                        let res = await this.$axios.delete(`email-notification/remove/${listedNotification.id}`);
                        if(res.data.status_code == "1242"){

                            this.$notify({
                                type: "success",
                                title: "Success",
                                message: res.data.message,
                            });

                            this.$store.commit('notifications_module/delete_notifications_list',index);

                            //removing deleted item from checked items list
                            this.checkedItems = this.checkedItems.filter((item) => {

                                if(item != listedNotification.id){
                                    
                                    return item;
                                    
                                }

                            });

                        }
                    }catch(error){

                        this.$message({
                            type: "error",
                            showClose: true,
                            message: error.response ? error.response.data.message : error.message,
                        });

                    }finally{

                        loader.close();

                        this.deletingItems = [];
                        this.deleteLoader = false;

                    }

                }).catch(() => {})

            }

        },
        //add & delete notification end

        //bulk action start
        notificationsBulkActionHandle(action){

            this.resetPreview();

            MessageBox.confirm(
                'Do you really want to make the changes?',
                'Confirm',
                {
                    distinguishCancelAndClose: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                }
            ).then(async ()=>{

                if(action == 'delete'){
                
                    let formData = new FormData();

                    formData.append('resource_type', 'notification');
                    formData.append("resource_ids", JSON.stringify(this.checkedItems));
                    formData.append("action", action);

                    this.deletingItems = _.cloneDeep(this.checkedItems)
                    this.deleteLoader = true;
                    
                    let loader = Loading.service({
                        text: "The selected notifications are being removed. Please wait!",
                        fullscreen: true,
                    });

                    try{
                        let res = await this.$axios.post('/bulk-action',formData);
                        if(res.data.status_code == "2031"){

                            this.$notify({
                                type: 'success',
                                title: 'Success',
                                message: 'The notifications have been successfully removed.',
                            });

                            let payload = {
                                action: 'delete',
                                listIds: this.checkedItems
                            }

                            this.$store.commit('notifications_module/notifications_bulk_action',payload);

                            this.checkedItems = [];
                            this.deletingItems = [];
                            this.deleteLoader = false;

                        }
                    }catch(error){

                        this.deletingItems = [];
                        this.deleteLoader = false;

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response ? error.response.data.message : error.message,
                        }); 

                    }finally{

                        loader.close();

                    }

                }

            }).catch(() => {})

        },
        //bulk action end

        //search notification start
        searchNotification(keyword){

            this.$refs.module_search.searchedKeyword = keyword
            
            this.$refs.module_search.search();

        },
        //search notification end

        //other methods start
        isDeletingItem(id){

            let find = this.deletingItems.find(x=>x == id);

            if(find){

                return true;

            }else{

                return false;

            }

        },
        checkedItemHandle(notificationId){

            if(!this.tourIsActive && notificationId != -1 && !this.previewForm.id){

                let index = this.checkedItems.findIndex(item => item == notificationId);

                if(index != -1){

                    this.checkedItems.splice(index,1)

                }else{

                    this.checkedItems.push(notificationId)

                }

            }

        },
        toggleIsCheckedAll(){

            if(!this.tourIsActive && !this.previewForm.id){

                if((this.checkedItems.length == this.notificationsList.length) ||
                (this.checkedItems.length == this.notificationsList.length)){

                    this.checkedItems = [];

                }else if(this.checkedItems.length < this.notificationsList.length){

                    this.checkedItems = [];
                    
                    this.notificationsList.forEach(notificaion => {

                        if(notificaion.id != -1){

                            this.checkedItems.push(notificaion.id)

                        }

                    });

                }

            }

        },
        clearSelection(){

            MessageBox.confirm(
                'Do you really want to uncheck the selected records?',
                'Confirm',
                {
                    distinguishCancelAndClose: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                }
            ).then(async () => {

                this.checkedItems = [];

            }).catch(() => {})

        },
        expand(){

            this.$refs.viewInputForm.classList.toggle('fullWidthSlide');

        },
        notificationsKeyEvent(e){

            if(e.key == 'Escape'){

                if(this.$refs.viewInputForm.classList.contains('fullWidthSlide')){

                    this.$refs.viewInputForm.classList.remove('fullWidthSlide');

                }else if(this.$refs.viewInputForm.classList.contains('activeRow')){

                    this.closePreview();

                }

            }

        },
        //other methods end

        //load customer emails method starts here
        async loadCustomerEmails(query){

            if(query){

                clearTimeout(this.optionsTimer);

                this.optionsTimer = setTimeout(async () => {

                    this.optionsLoading = true;
                    try{
                        let res = await this.$axios.get('/customer/email/all',{
                            params:{
                                offset: 15,
                                search: query ? query : null
                            }
                        });
                        if(res.data.status_code == "10211"){

                            this.options[0].emails = res.data.emails.data;

                            if(this.previewForm.id != '' && this.previewForm.id != -1){
                            
                                if(this.previewForm.recipientsList.length > 0){

                                    let recipientsNotListed = [];

                                    this.previewForm.recipientsList.forEach((recipient) => {

                                        if(!this.options[0].emails.some((listedEmail) => listedEmail.id == recipient.id)){

                                            recipientsNotListed.push(recipient);

                                        }

                                    });

                                    if(recipientsNotListed.length > 0){

                                        recipientsNotListed.forEach((recipient) => {

                                            this.options[0].emails.push(recipient);

                                        });

                                    }

                                }
                            
                            }

                        }
                    }catch(error){

                        this.$message({
                            type: "error",
                            showClose: true,
                            message: error.response ? error.response.data.message : error.message,
                        });

                    }finally{

                        this.optionsLoading = false;

                    }

                }, 500)

            }else{

                this.optionsLoading = true;
                try{
                    let res = await this.$axios.get('/customer/email/all',{
                        params:{
                            offset: 15,
                            search: query ? query : null
                        }
                    });
                    if(res.data.status_code == "10211"){

                        this.options[0].emails = res.data.emails.data;

                        if(this.previewForm.id != '' && this.previewForm.id != -1){
                        
                            if(this.previewForm.recipientsList.length > 0){

                                let recipientsNotListed = [];

                                this.previewForm.recipientsList.forEach((recipient) => {

                                    if(!this.options[0].emails.some((listedEmail) => listedEmail.id == recipient.id)){

                                        recipientsNotListed.push(recipient);

                                    }

                                });

                                if(recipientsNotListed.length > 0){

                                    recipientsNotListed.forEach((recipient) => {

                                        this.options[0].emails.push(recipient);

                                    });

                                }

                            }
                        
                        }

                    }
                }catch(error){

                    this.$message({
                        type: "error",
                        showClose: true,
                        message: error.response ? error.response.data.message : error.message,
                    });

                }finally{

                    this.optionsLoading = false;

                }

            }

        },
        //load customer emails method end here

        //tour methods start
        startNotificationsTour(){

            this.$tours.notificationsTour.start();

            this.tourIsActive = true;

            if(this.previewForm.id != ''){

                this.resetPreview();

            }

        },
        handleNextStep(currentStep){

            if(currentStep + 1 == 7){

                document.querySelector(".tableActionBtns").style.position = "static";

                document.querySelector(".productSlideBox").style.overflowY  = "hidden";

                this.createNotification();

            }

            if(currentStep >= 10){

                document.querySelector(".tableActionBtns").style.position = "sticky";

            }

            if(currentStep + 1 == 14){

                if(!this.$refs.viewInputForm.classList.contains('fullWidthSlide')){

                    this.expand();

                }

            }

            if(currentStep + 1 == 15){

                this.expand();

            }

        },
        handlePreviousStep(currentStep){

            if(currentStep == 7){

                this.resetPreview();

                document.querySelector(".tableActionBtns").style.position = "sticky";

                document.querySelector(".productSlideBox").style.overflowY  = "auto";

            }

            if(currentStep <= 11){

                document.querySelector(".tableActionBtns").style.position = "static";

            }

            if(currentStep == 14){

                if(this.$refs.viewInputForm.classList.contains('fullWidthSlide')){

                    this.expand();

                }

            }

            if(currentStep == 15){

                if(!this.$refs.viewInputForm.classList.contains('fullWidthSlide')){

                    this.expand();

                }

            }

        },
        handleTourStop(){

            document.querySelector(".tableActionBtns").style.position = "sticky";

            document.querySelector(".productSlideBox").style.overflowY  = "auto";

            this.resetPreview();

            this.tourIsActive = false;

        },
        handleTourFinish(){

            document.querySelector(".tableActionBtns").style.position = "sticky";

            document.querySelector(".productSlideBox").style.overflowY  = "auto";

            this.resetPreview();

            this.tourIsActive = false;

        },
        handleTourSkip(){

            document.querySelector(".tableActionBtns").style.position = "sticky";

            document.querySelector(".productSlideBox").style.overflowY  = "auto";

            this.resetPreview();

            this.tourIsActive = false;

        },
        //tour methods end

    },
    mounted(){

        this.$refs.notificationsTable.addEventListener('scroll', this.handleScrollEvent);

        window.addEventListener('keydown',this.notificationsKeyEvent);

        window.addEventListener('beforeunload', (event) => {

            let index = null;

            this.notificationsList.forEach((notification) => {

                if(notification.id == -1){

                    index = this.notificationsList.indexOf(notification);

                }

            });

            if(index != null){

                event.returnValue = "Are you sure you want to leave? entered information will be lost";

            }

        });

        //master search
        if(this.$route.query.search){
    
            this.$refs.module_search.searchedKeyword = this.$route.query.search

            if(this.$route.query.preview == 1){

                this.isMasterSearched = true

            }

            this.$router.replace({'query': null});

            this.$refs.module_search.search();

        }

    },
    async beforeMount(){

        this.notificationsLoading = true;
        try{

            if((!this.storeNotifications || this.storeNotifications.length == 0) && this.notificationsSearchedKeyword.length == 0){

                this.$store.dispatch('notifications_module/fetchStoreNotificationsRequest',{size:20, page: 1, loadData: 'new'});

            }else{

                this.notificationsList = _.cloneDeep(this.storeNotifications);
                this.notificationsLoading = false;

            }

            this.loadCustomerEmails(null);

        }catch(error){

            this.notificationsLoading = false;

            this.$message({
                type: "error",
                showClose: true,
                message: error.response ? error.response.data.message : error.message,
            });

        }

    },
    beforeDestroy(){

        window.removeEventListener('keydown',this.notificationsKeyEvent);

    },
    beforeRouteLeave(to, from, next){

        let index = null;

        this.notificationsList.forEach((notification) => {

            if(notification.id == -1){

                index = this.notificationsList.indexOf(notification);

            }

        });

        if(window.innerWidth <= 1024){

            document.body.classList.remove('activeIpad');
            document.body.classList.add('hideSideBar');

        }

        if((this.previewForm.id || index != null) && to.fullPath != '/login'){

            MessageBox.confirm(
                'You may have unsaved changes, Proceeding will discard them',
                'Confirm',
                {
                    distinguishCancelAndClose: true,
                    confirmButtonText: 'Process',
                    cancelButtonText: 'Stay',
                }
            ).then(()=>{

                this.resetPreview();

                next();

            }).catch(()=>{})

        }else{

            next();

        }

    },
}
window.myFunction = function (event) {
    // reset all menu items
    document.querySelectorAll(".sectionTabsLink ul li a.active").forEach(function (item) {
      item.classList.remove("active");
    });
    // mark as active selected menu item
    event.target.classList.add("active");
};
</script>

<style>

</style>