<template>
  <router-view></router-view>
</template>

<script>
  export default {
    name: 'LoginLayout',
  };
</script>

<style>
</style>